// Handling JWT authentication and refreshing

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseUserFromToken } from 'src/utils/Authenticate';
import axios from 'axios';

export interface AuthForm {
  email: string;
  password?: string;
  identifier?: string;
}

export interface CodeValidationForm {
  email: string;
  code: string;
}

export interface SignupForm {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  company_position: string;
  password: string;
  identifier: string;
  country: string;
  state: string;
  marketing_params: {
    source: string;
    campaign_medium: string;
    campaign_name: string;
    g_visitor_id: string;
  };
}

export interface ResetPasswordRequest {
  username: string;
  password: string;
  authcode: string;
  token: string;
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

/*
    @return {bool} - returns true if user was successfully logged in
*/
export const signIn = createAsyncThunk(
  'SIGNIN',
  async (values: AuthForm, { rejectWithValue }) => {
    return authInstance
      .post('/accounts/mtr/auth/login', {
        username: values.email,
        password: values.password,
        authcode: '0',
        identifier: values.identifier,
      })
      .then((response) => {
        localStorage.setItem('PLAYMPE_AUTH', JSON.stringify(response.data));
      })
      .catch((error) => {
        return rejectWithValue(error.response.status);
      });
  }
);

/*
    @return {response data} - returns reset password response data
*/
export const forgotPassword = createAsyncThunk(
  'FORGOT_PASSWORD',
  async (userName: string) => {
    return authInstance
      .get(`/accounts/mtr/auth/reset/${userName}`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
);

/*
    @return {bool} - returns reset password response data
*/
export const resetPassword = createAsyncThunk(
  'RESET_PASSWORD',
  async (payload: ResetPasswordRequest) => {
    return authInstance
      .put(`/accounts/mtr/auth/chgpwdreset`, payload)
      .then((response) => {
        return response;
      });
  }
);

/*
    @return {AuthForm} - returns check existent user response data
    code 0 = new user
    code 1 = signup requested for meter by caster user
    code 2 = user already exists
*/
export const checkExistentUser = createAsyncThunk(
  'CHECK_EXISTENT_USER',
  async (email: string) => {
    return authInstance
      .post(`/accounts/mtr/signup/email-verify`, { email })
      .then(() => {
        return { code: 0 };
      })
      .catch((err) => {
        return { code: err.response.data.error.code };
      });
  }
);

/*
    @return {AuthForm} - send verification code email
*/
export const sendSignupEmail = createAsyncThunk(
  'SEND_SIGNUP_EMAIL',
  async (values: AuthForm) => {
    return authInstance
      .post(`/accounts/mtr/verification-code/send`, values)
      .then(() => {
        return { ...values };
      })
      .catch((error) => {
        return error.code;
      });
  }
);

/*
    @return {string} - returns validate code response data
*/
export const validateCode = createAsyncThunk(
  'VALIDATE_CODE',
  async (payload: CodeValidationForm) => {
    return authInstance
      .post(`/accounts/mtr/verification-code/validate`, payload)
      .then(() => {
        return { code: payload.code, isCodeValid: true, hasResponseError: 0 };
      })
      .catch((err) => {
        return {
          code: payload.code,
          isCodeValid: false,
          hasResponseError: err.response.data.error.code,
        };
      });
  }
);

/*
    @return {string} - submit user registration request for sign-up
*/
export const registerUser = createAsyncThunk(
  'REGISTER_USER',
  async (payload: SignupForm) => {
    return authInstance
      .post(`/accounts/mtr/signup`, payload)
      .then(() => {
        return { isUserRegistered: true, code: '' };
      })
      .catch((err) => {
        return { isUserRegistered: false, code: err.code };
      });
  }
);

/*
    @return {token, refreshToken } - returns token and refreshToken
*/
export const getTokens = () => {
  const { 'access-token': token, 'refresh-token': refreshToken } =
    JSON.parse(localStorage.getItem('PLAYMPE_AUTH')!) || {};

  return { token, refreshToken };
};

/*
    @return {access-token} - returns new access-token and adds to local storage
*/
export const refreshTokens = (refreshToken: string) =>
  authInstance
    .put('/accounts/mtr/auth/refresh', { 'refresh-token': refreshToken })
    .then((response) => {
      localStorage.setItem('PLAYMPE_AUTH', JSON.stringify(response.data));
      return response.data['access-token'];
    })
    .catch(() => {
      localStorage.removeItem('PLAYMPE_AUTH');
      window.location.reload();
    });

/*
      Logout flow requires
      1. Deleting the refresh token via endpoint
      2. Redirecting user to signin page
  */
export const logout = async () => {
  try {
    const { refreshToken } = getTokens();

    const response = await authInstance.delete(
      `/accounts/mtr/auth/refresh?refresh-token=${refreshToken}`
    );

    if (response?.status === 200) {
      localStorage.removeItem('PLAYMPE_AUTH');
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

/*
      @return {object} - returns the current user
  */
export const getSessionUser = () => {
  const { token: accessToken, refreshToken } = getTokens();
  if (!accessToken || !refreshToken) return false;

  return parseUserFromToken(accessToken);
};

/*
      @return {bool} - whether user is authenticated
  */
export const isAuthenticated = () => {
  const User = getSessionUser();
  return User && User.username != null;
};

/*
      @return {bool} - whether user is authorized based on the roles
  */
export const isAuthorized = (required_roles: any) => {
  const User = getSessionUser();

  if (!isAuthenticated()) {
    return false;
  }

  let authorized = false;
  const ROLES = User.roles;

  if (!required_roles || required_roles.length === 0) {
    authorized = true;
  } else {
    if (!Array.isArray(required_roles)) {
      required_roles = [required_roles];
    }
    if (required_roles.indexOf('user') > -1) {
      // 'user' is the lowest level of authenticated user
      authorized = true;
    } else if (User && ROLES && ROLES.length > 0) {
      for (let i = 0; i < ROLES.length; i + 1) {
        if (required_roles.indexOf(ROLES[i]) > -1) {
          authorized = true;
          break;
        }
      }
    }
  }
  return authorized;
};
